<template>
  <div class="phonerVerify-page">
    <layout header="CASA LAPIN" :class="{'blur-page': showAlertError}" @goback="$router.push('/SelectLanguage')">
      <template v-slot:main>
        <v-row class="body-phoneVerify mt-5" no-gutters align-content="start">
          <v-col cols="12" class="text-center py-5 text-header">{{$t('phoneVerifyTitle')}}</v-col>
          <v-col cols="12" class="text-center py-5">
            <v-text-field outlined rounded background-color="white" class="input-text" hide-details dense height="44"
              :placeholder="$t('phoneNumber')" v-model="phoneNo" @keypress="isNumber()" @keyup.enter="sendOTP()">
            </v-text-field>
          </v-col>
          <v-col cols="12" class="text-center py-5">
            <v-btn rounded large block color="#9D662C" :disabled="timer != 31 || loadingState" @click="sendOTP()">
              <span v-if="timer == 31">{{$t('next')}}</span>
              <span v-else>{{timer}}</span>
            </v-btn>
          </v-col>
        </v-row>
      </template>
    </layout>
    <loading v-show="loadingState"></loading>
    <alert type="error" :header="$t('failed')" :title="textErr" v-show="showAlertError" @done="Done"></alert>
  </div>
</template>

<script>
  import Layout from "../../components/layout_2";
  import Loading from "../../components/loading";
  import Alert from "../../components/alert";
  export default {
    name: "PhoneVerify",
    components: {
      layout: Layout,
      loading: Loading,
      alert: Alert,
    },
    data: () => ({
      loadingState: false,
      timer: 31,
      showAlertError: false,
      textErr: "",
      phoneNo: '',
    }),
    watch: {
      timer(val) {
        if (val < 31 && val != 0) {
          setTimeout(() => {
            this.timer = this.timer - 1;
          }, 1000);
        } else if (val == 0) {
          this.timer = 31;
        }
      },
    },
    methods: {
      async sendOTP() {
        this.loadingState = true;
        try {
          let config = {
            headers: {
              "content-type": "application/json",
              "X-API-KEY": "OTPJFINzM8L6ZCmbIvce1BAFwihvDf3BF",
            },
          };
          let params = {
            phone_no: this.phoneNo,
          };
          let res = await this.$axios.post(
            "https://api.jfin.network/api/v2/paa/auth_phone",
            JSON.stringify(params),
            config
          );
          if (res.data.status_code == 200) {
            this.$store.commit("SET_OTPREF", res.data.data.otp_ref);
            this.$store.commit("SET_PHONE_AUTH", this.phoneNo);
            this.$router.push("/OtpVerify");
          } else {
            this.textErr = this.$i18n.locale == 'th' ? "เบอร์โทรศัพท์มือถือไม่ถูกต้อง <br> กรุณากรอกใหม่" : "Invalid number <br> Please try again.";
            this.showAlertError = true;
          }
        } catch (err) {
          this.textErr = this.$i18n.locale == 'th' ? "กรุณาเช็คการเชื่อมต่อ" : "Please check connection.";
          this.showAlertError = true;
        }
        this.timer = 30;
        this.loadingState = false;
      },
      Done() {
        this.showAlertError = false;
      },
      isNumber(evt) {
        evt = evt ? evt : window.event;
        var charCode = evt.which ? evt.which : evt.keyCode;
        if (charCode == 43) {} else if (
          charCode > 31 &&
          (charCode < 48 || charCode > 57) &&
          charCode !== 46
        ) {
          evt.preventDefault();
        } else {
          return true;
        }
      },
    },
  };
</script>

<style lang="scss">
  .phonerVerify-page {
    height: 100%;

    .body-phoneVerify {
      max-width: 420px;
      padding: 0px 32px;

      .text-header {
        font-size: 14px;
        font-weight: 800;
      }
    }
  }
</style>